body {
  margin: 0;
  font-family: "Raleway";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  text-align: left;
  font-family: "Kaushan Script";
}

h2 {
  text-align: left;
  margin: 5pt 0pt;
}

p {
  text-align: left;
  font-size: 14pt;
  line-height: 22pt;
  margin: 20px 0 0 0;
  @media (max-width: 786px) {
    font-size: 16pt;
  }
}

.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 100%;
}

.each-slide-effect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}